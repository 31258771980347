import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { Seal } from '@/features/brearley-logo'
import { DatoGatsbyImage } from '@/features/common/'
import { DatoStructuredText } from '@/features/common/'
import { absoluteFill, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.CoachFragment | null
}

export const Coach = ({ data, ...props }: Props): JSX.Element => {
  const styles = {
    coach: css`
      padding: 1.5rem 1.5em 1.5rem 1.5rem;
      background: ${colors.gray98};
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto auto 1fr;
      grid-column-gap: 1.25em;
      align-items: flex-start;
      ${mq().s} {
        grid-template-rows: 1fr 1fr auto;
      }
    `,
    headshot: css`
      width: 8rem;
      box-sizing: border-box;
      position: relative;
      grid-row: 1 / 4;
      display: flex;
      [data-gatsby-image-wrapper] img {
        border-radius: 50%;
      }
      ${data?.headshot &&
      css`
        &:after {
          content: '';
          ${absoluteFill}
          border-radius: 50%;
          border: 1px solid #00000011;
          box-sizing: border-box;
        }
      `}
      ${mq().s} {
        grid-row: 1 / 3;
      }
    `,
    seal: css`
      width: 100%;
    `,
    name: css`
      font-family: var(--ff-body);
      font-size: var(--fs-24);
      font-weight: 450;
      margin: 0.5em 0 0;
      ${mq().s} {
        align-self: flex-end;
      }
    `,
    title: css`
      font-family: var(--ff-body);
      font-size: var(--fs-15);
      color: ${colors.gray45};
      /* text-transform: uppercase; */
      font-weight: 425;
      margin: 0.75em 0 0;
    `,
    description: css`
      font-weight: 350;
      ${mq().s} {
        grid-column: 1 / -1;
      }
    `,
  }
  return (
    <div
      css={styles.coach}
      {...props}
    >
      <div css={styles.headshot}>
        {data?.headshot ? (
          <DatoGatsbyImage
            image={data?.headshot?.gatsbyImageData}
            alt={data?.headshot?.alt || data?.name || ''}
          />
        ) : (
          <Seal
            css={styles.seal}
            color={colors.red}
          />
        )}
      </div>
      <h3 css={styles.name}>{data?.name}</h3>
      <h4 css={styles.title}>{data?.title}</h4>
      <div css={styles.description}>
        <DatoStructuredText data={data?.description} />
      </div>
    </div>
  )
}

export const AthleticsTeamCoachFragment = graphql`
  fragment Coach on DatoCmsCoach {
    id: originalId
    __typename
    name
    title
    description {
      value
    }
    education {
      location
      degree
    }
    headshot {
      gatsbyImageData(
        imgixParams: {
          fit: "crop"
          crop: "focalpoint"
          q: 50
          ar: "1:1"
        }
        width: 180
      )
      alt
    }
  }
`
